<template>
  <v-container>
    <Table ref="table" @edit_trans="edit_trans($event)"></Table>
    <Dialog @close_dialog="modal = false" @trans_saved="trans_saved()" :section="section" :modal="modal"></Dialog>

    <v-snackbar width="auto" right v-model="snackbar">
        تم تحديث الترجمة بنجاح
      <template v-slot:action="{ attrs }">
        <v-icon
          color="success"
          v-bind="attrs"
          >check</v-icon
        >
      </template>
    </v-snackbar>

  </v-container>
</template>

<script>
import Table from "@/components/translation/table";
import Dialog from "@/components/translation/dialog";
export default {
  nmae: "static",
  data() {
    return {
        snackbar:false,
      modal: false,
      section: {
        keys: [],
      },
    };
  },
  components: {
    Table,
    Dialog,
  },
  methods: {
    edit_trans(ev) {
      this.modal = true;
      this.section = Object.assign({}, ev.item);
    },
    trans_saved() {
    this.$refs.table.fetch_trans();
    this.snackbar = true;
    }
  },
};
</script>
