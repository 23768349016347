<template>
  <v-container>
    <v-data-table :items="sections" :headers="headers">
      <template v-slot:[`item.actions`]="{ item }">
        <v-btn @click="$emit('edit_trans', { item: item })" icon small>
          <v-icon small>edit</v-icon>
        </v-btn>
      </template>
    </v-data-table>
  </v-container>
</template>

<script>
export default {
  name: "Table",
  data() {
    return {
        sections:[],
      headers: [
        {
          value: "section",
          text: "القسم",
          align: "center",
        },
        {
          value: "actions",
          text: "اجراءات",
          align: "center",
        },
      ],
    };
  },
  methods: {
    fetch_trans() {
        this.$store.dispatch("public__request", {
            config:{
                url:"static_trans",
                method:'get'
            }
        }).then(res=> {
            this.sections = Object.assign([], res.data.data);
            console.log(res.data.data);
        }).catch(err=> {
            console.log(err.response);
        })
    },
  },
  created() {
    this.fetch_trans();
  }
};
</script>
