<template>
  <v-form ref="form" v-model="form">
    <v-dialog persistent :value="modal" scrollable max-width="500">
      <v-card>
        <v-card-title>اسم القسم</v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <v-tabs class="mb-3" v-model="tabs" background-color="gray">
            <v-tabs-slider color="primary"></v-tabs-slider>

            <v-tab v-model="tabs" v-for="(lang, i) in langs" :key="i">
              {{ lang.lang_name }}
            </v-tab>
          </v-tabs>
          <!--/tabs -->

          <v-tabs-items v-model="tabs">
            <v-tab-item v-for="(lang, i) in langs" :key="i">
              <v-row class="pt-4">
                <v-col
                  v-for="(item, i) in section.keys"
                  :key="i"
                  cols="12"
                  sm="6"
                >
                  <v-text-field
                    v-model="item.desc[lang.locale]"
                    :error-messages="item.error && tabs == 0 ? 'حقل مطلوب' : ''"
                  >
                    <template slot="label">
                      <strong v-if="tabs == 0" class="red--text">*</strong>
                      {{ item.label }}
                    </template>
                  </v-text-field>
                </v-col>
              </v-row>
            </v-tab-item>
          </v-tabs-items>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-btn :disabled="save_loader" :loading="save_loader" @click="save_trans()" color="primary"> حفظ </v-btn>
          <v-btn @click="close_dialog()" text color="error">الغاء</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-form>
</template>

<script>
export default {
  name: "Dialog",
  props: ["section", "modal"],
  data() {
    return {
      tabs: 0,
      form: false,
      langs: [],
      save_loader:false,
    };
  },
  computed: {
    def_locale() {
      return this.langs.find((e) => e.is_default == 1).locale;
    },
  },
  methods: {
    get_lang() {
      this.$store
        .dispatch("public__request", {
          config: {
            url: "languages",
            method: "get",
          },
        })
        .then((res) => {
          this.langs = Object.assign([], res.data.data);
        });
    },
    validation() {
      let errors = [];
      this.section.keys.forEach((e, i) => {
        if (!e.desc[this.def_locale]) {
          errors.push(i);
          this.section.keys[i].error = true;
        }
      });

      if(errors.length > 0 ) {
        this.tabs = 0;
        return false;
      } else {
        return true;
      }
    },
    save_trans() {
      if (this.validation()) {
        this.save_loader = true;
        this.$store
          .dispatch("public__request", {
            config: {
              url: "static_trans",
              method: "post",
            },
            data: this.section,
          })
          .then((res) => {
            this.$emit("trans_saved");
            this.save_loader = false;
            this.close_dialog();
          })
          .catch((err) => {
            console.log(err.response);
          });
      } else {
        console.log("not_valid");
      }
    },
    close_dialog() {
        this.$emit("close_dialog");
    }
  },
  created() {
    this.get_lang();
  },
};
</script>
